<template>
<v-card flat class="gradient-card" dark color="blue darken-4">
  <v-card-text class="text-center pt-1 pb-1">
    <v-icon size="30" class="mb-2">mdi-shimmer</v-icon>
<p class="font-weight-black white--text">Generate professional financial reports with CFO AI.</p>

  </v-card-text>
<v-card-actions class="mt-0 pt-0">
  <v-btn target="_blank" :href="'https://cfoai.built.africa/?s=' + getAccessToken()" color="#F9E251" block light rounded depressed>Generate Report<sup><v-icon x-small>mdi-open-in-new</v-icon></sup></v-btn>
</v-card-actions>
</v-card>
</template>
<script>
import {defineComponent} from 'vue'
import {getAccessToken} from "../../utils";

export default defineComponent({
  name: "AdCard",
  methods: {getAccessToken}
})
</script>

<style scoped>
.gradient-card{
  background: radial-gradient(circle at -8.9% 51.2%, rgb(255, 124, 0) 0%, rgb(255, 124, 0) 15.9%, rgb(255, 163, 77) 15.9%, rgb(255, 163, 77) 24.4%, rgb(19, 30, 37) 24.5%, rgb(19, 30, 37,0.3) 66%);
}
</style>