<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <v-btn
            to="/"
            text
            :color="$vuetify.theme.dark ? 'white' : 'blue'"
        >
          <v-icon>mdi-arrow-left</v-icon>
          {{ $t("main.back") }}
        </v-btn>
        <span class="display-1 font-weight-light">Accounting</span>
      </v-col>

      <v-col cols="12" sm="3" v-for="(menu, index) in menus" :key="index">
        <menue-card
            :title="menu.title"
            :desription="menu.description"
            :icon="menu.icon"
            :route="menu.route"
        ></menue-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MenueCard from "./MenueCard.vue";

export default {
  components: {MenueCard},
  name: "AccountingMenu",
  data() {
    return {
      menus: [
        {
          title: "Bank Transfers",
          description:
              "Manage records on funds moved from one bank account to another",
          icon: "mdi-bank-transfer",
          route: "/accounting/banktransfer",
        },
        {
          title: "Bank Reconciliation",
          description: "Align your bank statements with your bank accounts",
          icon: "mdi-folder-sync-outline",
          route: "/accounting/bank-reconciliation",
        },
        {
          title: "Chart Of Accounts",
          description:
              "View and manage the structure of accounts for this business",
          icon: "mdi-sitemap",
          route: "/accounting/accounts",
        },
        {
          title: "Journals",
          description: "Create and manage double-entry journals",
          icon: "mdi-table-settings",
          route: "/accounting/journal",
        },
        {
          title: "Loans",
          description: "Record loans and loan repayments",
          icon: "mdi-cash-clock",
          route: "/accounting/loans",
        },
        {
          title: "Files",
          description:
              "View all files you've attached to invoices and expenses here.",
          icon: "mdi-folder-outline",
          route: "/accounting/files",
        },
        {
          title: "Sync Transactions",
          description:
              " Synchronize your data from other platforms and E-banking portals.",
          icon: "mdi-cloud-sync-outline",
          route: "/accounting/sync",
        },
        {
          title: "Tags",
          description: "Track revenue and expenses using Tags.",
          icon: "mdi-tag",
          route: "/accounting/tags",
        },
        {
          title: "Budgets",
          description: "Track and analyse projected income, expenses and profit compared to actual transactions",
          icon: "mdi-finance",
          route: "/accounting/budget",
        },
      ],
    };
  },
};
</script>
<v-container>
<v-row>
  <v-col cols="12" class="d-flex align-center">
    <v-btn
        to="/"
        text
        :color="$vuetify.theme.dark ? 'blue' : 'blue darken-4'"
    >
      <v-icon>mdi-arrow-left</v-icon>
      {{ $t("main.back") }}
    </v-btn>
    <span
        class="font-weight-bold"
        :class="$vuetify.theme.dark ? '' : 'text--darken-4'"
        style="font-size: 2rem"
    >Accounting</span
    >
  </v-col>
  <v-col cols="12" sm="3" v-for="(menu, index) in menus" :key="index">
    <menue-card
        :title="menu.title"
        :desription="menu.description"
        :icon="menu.icon"
        :route="menu.route"
    ></menue-card>
  </v-col>
</v-row>
</v-container>
import MenueCard from "./MenueCard.vue";