<template>
    <v-system-bar
        style="transition:0.3s ease-in-out"
        app
        fixed
        dark
        class="text-center"
        :color="colorMap[status]"
        v-if="show_bar">
      <span class="d-block text-center white--text" style="width: 100%">
        {{ toast_msg }}

      </span>
    </v-system-bar>
</template>

<script>
export default {
    name: "ImportAlerts",
    data() {
        return {
            toast_msg: "hello there.....",
            status: "",
            show_bar: false,
            colorMap: {
                "pending": "info",
                "completed": "success",
                "failed": "error"
            }
        }
    },
    methods: {
        listenForImportEvents() {
            const chProcessing = 'data_processing_' + this.businessId;
            const chCompleted = 'data_processing_completed_' + this.businessId;
            const chFailed = 'data_processing_failed_' + this.businessId;

            console.log(chProcessing);

            this.sockets.subscribe(chProcessing, (e) => {

              console.log(e);

                this.toast_msg =  e.message;
                this.status = "pending"
                this.show_bar = true
            });

            this.sockets.subscribe(chCompleted, (e) => {
                this.toast_msg =  e.message;
                this.status = "completed"
                setTimeout(() => {
                    this.show_bar = false
                }, 5000)
            });

            this.sockets.subscribe(chFailed, (e) => {
                this.toast_msg = e.message;
                this.status = "failed"
                setTimeout(() => {
                    this.show_bar = false
                }, 5000)
            });
        },
    },
    computed: {
        businessId() {
            return this.$store.state.user.user_infor.current_business.id;
        },
    },
    mounted() {
            this.listenForImportEvents()
    }
}
</script>
