<template>
  <div>
    <v-dialog v-model="createDialog" :loading="saving_progress" scrollable persistent
              :width="saving_progress ? '300' : '60%'" v-if="user"
              :fullscreen="$vuetify.breakpoint.mobile">
      <v-card>
        <v-card-text class="mt-8">
          <p v-if="saving_progress" class="pa-5 text-center">
            <center>
              <v-progress-circular class="d-block" indeterminate color="blue darken-4" size="65"></v-progress-circular>
              Initializing your account,please wait...
            </center>
          </p>
          <v-row dense>

            <v-col cols="12" sm="6" class="pa-3" v-if="!saving_progress">
              <v-row dense>
                <v-col cols="2">
                  <photo-selector-component :user-only="true"  @updated="(url) => (previewURL = url)"></photo-selector-component>

                </v-col>
              </v-row>
              <v-form class="mt-4" ref="business_form" v-if="!saving_progress">
                <v-text-field outlined :label="$t('main.business_name_label') + ' *'" v-model="business_name"
                              :rules="business_name_rules" hint="This field is mandatory" :disabled="saving_progress"
                              dense>
                </v-text-field>
                <v-row dense>
                  <!--EMAIL-->
                  <v-col cols="6" sm="6">
                    <v-text-field outlined :label="$t('main.business_email_label') + ' *'" v-model="business_email"
                                  type="email" :rules="[email_rules.email]" :disabled="saving_progress" dense>
                    </v-text-field>
                  </v-col>
                  <!--PHONE-->
                  <v-col cols="6" sm="6">
                    <v-text-field outlined :label="$t('main.business_tel_label')" v-model="business_phone" type="tel"
                                  :disabled="saving_progress" dense>
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row dense>
                  <!--COUNTRY-->
                  <v-col cols="12" sm="12">
                    <v-autocomplete outlined :items="$store.state.countries" item-value="id" item-text="name"
                                    :label="$t('main.business_country_label') + ' *'" v-model="country_id"
                                    hint="This field is mandatory"
                                    :disabled="saving_progress" dense :loading="progress">
                      <template v-slot:item="{ item }">
                        <flag class="mr-2" :iso="item.code"></flag>

                        {{ item.name }}
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!--REGION-->
                  <v-col cols="12" sm="12">
                    <v-autocomplete
                        :loading="loadingRegion"
                        dense
                        :disabled="loadingRegion"
                        outlined
                        :rules="$store.state.requiredRule"
                        v-model="region"
                        :items="regions"
                        label="State/Region"
                        item-text="name"
                        item-value="id"
                    >
                      <template v-slot:no-data>
                        <p class="text-center pa-3">No regions to show, select a country to see regions</p>
                      </template>

                    </v-autocomplete>
                  </v-col>

                  <v-col cols="12" sm="12">
                    <v-select dense outlined v-model="size" :rules="$store.state.requiredRule" :items="sizes"
                              label="Business Size"></v-select>
                  </v-col>

                  <!--CURRENCY-->
                  <v-col cols="6" sm="6">
                    <v-autocomplete outlined :items="$store.state.currencies" item-value="id" item-text="code"
                                    :label="$t('main.business_currency_label') + ' *'" v-model="currency_id"
                                    :hint="$t('main.required_hint')" :disabled="saving_progress" dense
                                    :loading="progress"
                                    :search-input.sync="currencySearch" :filter="customFilter">
                      <template v-slot:item="{ item }">
                        {{ item.currency }} {{ item.symbol }}({{ item.code }})
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <!--INDUSTRY-->
                  <v-col cols="6" sm="6">
                    <v-autocomplete outlined :items="$store.state.industries" item-value="id" item-text="name"
                                    :label="$t('main.business_industry_label') + ' *'" v-model="$store.state.industry"
                                    :rules="industry_rules" :hint="$t('main.required_hint')" :disabled="saving_progress"
                                    :loading="progress" dense></v-autocomplete>
                  </v-col>
                </v-row>
              </v-form>

              <v-alert v-if="error_message" border="bottom" transition="fade-transition" color="red"
                       class="white--text mx-4" elevation="8" type="error">{{ error_msg }}
              </v-alert>
            </v-col>
            <v-col cols="12" sm="6" v-if="!saving_progress && $vuetify.breakpoint.smAndUp">

              <v-card flat class="grey lighten-5" style="height: 100%">
                <v-card-text style="color: #130b9c!important;; text-align: center !important;">
                  <center>

                    <v-img class="mt-16 welcome-box" width="200"
                           src="https://built-storage.ams3.cdn.digitaloceanspaces.com/Built/welcome.svg"></v-img>

                  </center>

                  <div class="welcome-box">

                    <h1 class="font-weight-light text-center text-h3 mt-6">Hi
                      {{ $store.state.user.user_infor.first_name }},</h1>
                    <p class="text-center text-h6 font-weight-light black--text">
                      {{ $t("main.get_started_message") }}
                    </p>
                  </div>
                  <v-btn x-small text class="text-muted" @click="logout" :loading="progress">
                    {{ $t("main.logout") }}
                    <v-icon x-small>mdi-logout</v-icon>
                  </v-btn>

                </v-card-text>
              </v-card>

            </v-col>
          </v-row>


        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn depressed x-large v-if="!saving_progress" rounded @click="create_business" dark
                     color="blue darken-4"
                     block>{{
                  $t("main.create_business")
                }}
                <v-icon>mdi-check-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {getAccessToken, setAccessToken} from "@/utils";
import PhotoSelectorComponent from "../components/patials/PhotoSelectorComponent.vue";

export default {
  name: "onboard",
  components: {PhotoSelectorComponent},
  data() {
    return {
      createDialog: false,
      business_name: "",
      email_rules: {
        email: (value) => {
          const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      business_name_rules: [(value) => !!value || "Business Name is Required."],
      industry_rules: [(value) => !!value || "Please select your industry"],

      business_email: "",
      business_phone: "",
      business_address: "",
      business_bank_name: "",
      business_account_name: "",
      business_account_number: "",
      business_location: "",
      saving_progress: false,
      currencies: [],
      industries: [],
      countries: [],
      country_id: null,
      currency_id: null,

      sizes: [
        {
          value: "less_than_5_employees",
          text: "Less than 5 employees",
        },
        {
          value: "5_to_10_employees",
          text: "5 to 10 employees",
        },
        {
          value: "10_to_30_employees",
          text: "10 to 30 employees",
        },

        {
          value: "30_to_50_employees",
          text: "30 to 50 employees",
        },
        {
          value: "More_than_50_employees",
          text: "More than 50 employees",
        },
      ],
      size: "",
      industry: null,
      logo_error:
          "Logo size too big, please select a smaller image. Image size should not be more than 500kb ",
      image_error: false,
      img_blink: true,
      progress: false,
      imgsrc: "",
      previewURL: "",
      logo: [],
      invoice_prefix: "INV",
      error_message: false,
      error_msg: "Error message goes here!",
      region: "",
      regions: [],
      loadingRegion: false,
      currencySearch: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    country() {
      return this.$store.state.countries.filter(country => country.id === this.country_id)
    },
    promoPackage() {
      return localStorage.getItem("package");
    },
  },
  methods: {
    customFilter(item, queryText, itemText) {
      return (
          item.code.toLowerCase().includes(queryText.toLowerCase()) ||
          item.country.toLowerCase().includes(queryText.toLowerCase()) ||
          item.currency.toLowerCase().includes(queryText.toLowerCase())
      );
    },

    passChange(e) {
      this.progress = true;
      this.image_error = false;
      this.previewURL = "";
      this.logo = e.target.files[0];
      const fr = new FileReader();
      fr.readAsDataURL(this.logo);
      fr.onload = () => {
        this.previewURL = fr.result;
        this.img_blink = false;
        this.progress = false;
        this.img_blink = true;
      };
    },
    logout() {
      this.progress = true;
      this.$store.state.user = null;

      setAccessToken(null);

      this.$router.push({path: "/auth/login"});

      //this.$store.commit('init_app');
      this.progress = false;
      location.reload();
    },
    setCurrency(name) {
      let currency = this.$store.state.currencies.filter(currency => currency.country === name)?.[0]
      console.log(currency)
      if (currency) {
        this.currency_id = currency.id
      } else {
        this.currency_id = ""
      }
    },
    create_business() {
      if (this.$refs.business_form.validate()) {
        this.saving_progress = true;

        let business_data = new FormData();
        business_data.append("name", this.business_name);
        business_data.append("invoice_id", this.invoice_prefix);
        business_data.append("address", this.business_address);
        business_data.append("email", this.business_email);
        business_data.append("country_id", this.country_id);
        business_data.append("region_id", this.region);
        business_data.append("currency_id", this.currency_id);
        business_data.append("industry_id", this.$store.state.industry);
        business_data.append("location", this.business_location);
        business_data.append("phone_number", this.business_phone);
        business_data.append("bank_name", this.business_bank_name);
        business_data.append("account_number", this.business_account_number);
        business_data.append("account_name", this.business_account_name);
        business_data.append("employee_size", this.size);
        business_data.append("active", 1);
        business_data.append("logoURL", this.previewURL);
        business_data.append("is_retail", 0);
        business_data.append("is_service", 1);
        if (this.platform) {
          business_data.append("media", this.platforms[this.platform]);
        }
        axios
            .post("/api/createbusiness", business_data)
            .then((res) => {
              if (this.promoPackage) {
                window.location = "/plans";
              } else {
                window.location = "/?showOnboarding=true";
              }
            })
            .catch(() => {
              // this.error_message = true;
              // this.error_msg = "Something went wrong, please check and try again";
              this.saving_progress = false;
            });
      } else {
        this.error_message = true;
        this.error_msg = "Please fill in the required field(s) correctly!";
        setTimeout(() => {
          this.error_message = false;
          this.$refs.business_form.resetValidation();
        }, 5000);
      }
    },

    set_defaults() {
      this.saving_progress = true;
      axios
          .get("http://ip-api.com/json/")
          .then((res) => {
            if (res.data.status === "success") {
              const code = res.data.countryCode;

              const currentCountry = this.$store.state.countries.find(
                  (c) => c.code === code
              );

              if (currentCountry) {
                this.country_id = currentCountry.id;
                this.getRegions(this.country_id);

                const currentCurrency = this.$store.state.currencies.find(
                    (currency) =>
                        currency.country.toLowerCase() ===
                        currentCountry.name.toLowerCase()
                );

                if (currentCurrency) {
                  this.currency_id = currentCurrency.id;
                }
              }
            }
            this.saving_progress = false;
          })
          .catch((error) => {
            this.saving_progress = false;
          });
    },
    get_industries_currencies_and_countries() {
      this.saving_progress = true;

      axios
          .get("/api/getindustries")
          .then((res) => {
            this.$store.state.industries = res.data;
            axios
                .get("/api/getcurrencies")
                .then((res) => {
                  this.$store.state.currencies = res.data;
                  axios
                      .get("/api/getcountries")
                      .then((res) => {
                        this.saving_progress = false;

                        this.$store.state.countries = res.data;

                        this.setCurrency(this.country[0].name)
                        this.set_defaults();
                      })
                      .catch((err) => {
                      });
                })
                .catch((err) => {
                });
          })
          .catch((err) => {
          });
    },
    getRegions(id) {
      this.loadingRegion = true;
      let url = `/api/getregions/?country_id=${id}`;
      axios.get(url).then((response) => {
        this.regions = response.data;
        this.loadingRegion = false;
      });
    },
  },
  watch: {
    country_id() {
      this.getRegions(this.country_id);
      if (this.$store.state.currencies.length > 0) {
        this.setCurrency(this.country[0].name)
      }
    },
  },

  created() {
    this.$store.state.initializing_app = false;

    axios.defaults.headers.common = {
      Authorization: `Bearer ${getAccessToken()}`,
    };

    if (this.user && !this.user.user_infor.has_business) {
      this.createDialog = false;
      this.createDialog = true;
      this.get_industries_currencies_and_countries();
    } else {
      this.$router.back();
    }
  },
  mounted() {
    if (this.user && this.user.user_infor.has_business) {
      this.$router.back();
    }
  },
};
</script>
<style>

.welcome-box {

  animation: zoom-in-zoom-out 1s ease-in-out;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(0.5, 0.5);
  }
  50% {
    transform: scale(1.2, 1.2);
  }
  100% {
    transform: scale(1, 1);
  }
}
</style>
