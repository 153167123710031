<template>
  <div class="py-4">
    <v-row no-gutters>
      <v-col :sm="columnSize" xs="12">
        <v-card flat class="mt-5" height="100%">
          <v-card-text>
            <v-row>
              <v-col cols="12" class="ma-5">
                <center>
                  <v-img
                    src="/img/app_name.png"
                    width="100"
                    class="mb-3"
                  ></v-img>
                </center>
                <h3 class="font-weight-light text-center">
                  Create Your Built Account
                </h3>
              </v-col>
            </v-row>

            <v-stepper v-model="step" elevation="0" flat>
              <v-stepper-header
                style="border: none !important; box-shadow: none !important"
              >
                <v-stepper-step
                  :color="step > 1 ? 'green' : 'blue'"
                  :complete="step > 1"
                  step="1"
                  >Personal Info.
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :color="step > 2 ? 'green' : 'blue'"
                  :complete="step > 2"
                  step="2"
                  >Account Info.
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-form
                    v-model="personal_infor"
                    ref="person_form"
                    @keyup.native.enter="personal_infor && goto_account($event)"
                  >
                    <v-row dense>
                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="first_name"
                          :rules="first_name_rules"
                          label="First Name"
                          class="mt-2"
                          outlined
                          autofocus
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="last_name"
                          :rules="first_name_rules"
                          label="Last Name"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <!--											GENDER-->
                      <v-col cols="12" sm="12" class="">
                        <v-select
                          outlined
                          v-model="gender"
                          :items="items"
                          item-text="gender"
                          item-value="value"
                          label="Gender"
                          :disabled="!first_name && !last_name"
                          @change="handleGenderChange"
                        ></v-select>
                      </v-col>

                      <v-col cols="12" sm="12">
                        <phone-number-input
                          @input="(ph) => (phone_number = ph)"
                          :isEmailLogin="true"
                        ></phone-number-input>
                      </v-col>
                    </v-row>
                  </v-form>

                  <v-row>
                    <v-col cols="12" sm="8" xs="12" offset-sm="4" class="mt-2">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-4"
                        @click="goto_account"
                        block
                        rounded
                        x-large
                        depressed
                        dark
                        :disabled="!Number(phone_number)"
                      >
                        Continue
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-alert type="warning" v-if="exist"
                    >This email is already taken, if this is your email please
                    log in
                  </v-alert>

                  <v-form
                    v-model="account_infor"
                    ref="account_form"
                    @keyup.native.enter="account_infor && sign_up()"
                  >
                    <v-text-field
                      type="email"
                      label="E-Mail"
                      class="mt-2"
                      outlined
                      :rules="email_rules"
                      v-model="new_email"
                      @blur="check_exist()"
                      :loading="checking"
                      :autofocus="true"
                      :disabled="progress"
                    >
                    </v-text-field>

                    <v-text-field
                      v-model="new_password"
                      :rules="password_rules"
                      :type="show_pass1 ? 'text' : 'password'"
                      :append-icon="
                        show_pass1 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      "
                      label="Password"
                      counter
                      outlined
                      hint="Must be at least 8 characters long"
                      persistent-hint
                      :disabled="progress"
                      @click:append="show_pass1 = !show_pass1"
                    ></v-text-field>

                    <v-text-field
                      v-model="confirm_password"
                      :rules="password_rules"
                      :type="show_pass2 ? 'text' : 'password'"
                      label="Repeat Password"
                      counter
                      outlined
                      :disabled="progress"
                      :append-icon="
                        show_pass2 ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
                      "
                      @click:append="show_pass2 = !show_pass2"
                    ></v-text-field>

                    <span v-if="!hasMediaToken"
                      >How did you hear about us?</span
                    >
                    <v-autocomplete
                      v-if="!hasMediaToken"
                      label="Select option"
                      :items="filteredMedia"
                      item-value="media_name"
                      item-text="media_name"
                      v-model="media"
                      clearable
                      outlined
                      :disabled="progress"
                    >
                    </v-autocomplete>
                  </v-form>
                  <v-alert type="error" v-if="login_error">
                    <h5>{{ error_msg }}</h5>
                  </v-alert>

                  <v-row>
                    <v-col cols="12" sm="1" class="pl-4">
                      <v-checkbox
                        class="p-0 m-0"
                        color="blue darken-4"
                        v-model="read_terms"
                      ></v-checkbox>
                    </v-col>

                    <v-col cols="12" sm="11">
                      Agree to Built Accounting's
                      <a
                        href="https://builtaccounting.com/terms-conditions/"
                        target="_blank"
                        >Terms</a
                      >
                      and
                      <a
                        target="_blank"
                        href=" https://builtaccounting.com/terms-conditions/"
                        >Conditions</a
                      >
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col cols="12" sm="6">
                      <v-btn color="blue" @click="step = step - 1" text>
                        <v-icon>mdi-arrow-left</v-icon>
                        Back
                      </v-btn>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-btn
                        color="blue darken-4"
                        @click="sign_up"
                        :disabled="!read_terms"
                        :loading="progress"
                        x-large
                        rounded
                        dark
                        depressed
                        block
                      >
                        Sign Up Now
                        <v-icon>mdi-arrow-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <center>
                    <h3 class="font-weight-light">
                      Verification code was sent to
                      <strong>{{ phone_number }}</strong>
                    </h3>
                    <h4>Enter the code to verify your phone number</h4>
                  </center>
                  <o-t-p-component
                    :phone_number="phone_number"
                    :id="otp_id"
                    v-if="!progress"
                    @done="VerificationDone"
                  ></o-t-p-component>
                  <v-btn text v-if="!progress" @click="step = 2"
                    ><v-icon>mdi-arrow-left</v-icon>Back</v-btn
                  >
                  <v-btn color="blue" text :loading="progress" @click="SendOTP"
                    >Resend code</v-btn
                  >
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>

            <center class="mt-5">
              <h2 class="mt-3 mb-3">OR</h2>
              <v-btn
                class="mt-3 mb-3"
                color="blue"
                outlined
                dark
                x-large
                rounded
                depressed
                block
                style="text-decoration: none"
                :href="$store.state.baseURL + '/' + 'login/google'"
                >Sign up with google <v-icon>mdi-google</v-icon></v-btn
              >

              Already have a Built account?
              <v-btn color="blue darken-4" dark text rounded to="/login"
                >Login</v-btn
              >
            </center>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" xs="12" v-if="isValidPackage">
        <v-card
          flat
          class="mt-5 pa-4"
          height="100%"
          style="
            background: url('/img/background.png');
            background-size: contain;
          "
        >
          <v-card-text style="height: 100%">
            <div
              v-if="subscription_package === 'trial'"
              style="padding-top: 200px"
              class="white--text"
            >
              <h3 class="font-weight-light text-h3 text-center">
                Sign up to try
              </h3>
              <p class="text-center" style="margin-top: 50px; color: #f9e251">
                <span class="display-2"> for 30 days </span>
              </p>
            </div>
            <div v-else style="margin-top: 50px; padding-top: 30px">
              <h3
                class="text-subtitle-1 white--text text-center"
                style="margin-top: 30px"
              >
                Sign up to buy the
              </h3>

              <h3
                class="text-h4 text-capitalize font-weight-bold white--text text-center"
                style="margin-top: 30px"
              >
                {{ subscription_package }} plan
              </h3>
              <center>
                <h3
                  class="text-h5 white--text text-center"
                  style="margin-top: 40px"
                >
                  up to
                </h3>
              </center>
              <center>
                <p class="text-h4" style="color: #f9e251">
                  <span class="display-4 font-weight-bold">50%</span>
                  OFF
                </p>
              </center>
              <v-card
                style="margin-top: 50px; background: transparent; color: white"
                height="100%"
                flat
              >
                <div class="d-flex flex-column justify-space-between pa-5">
                  <div class="d-flex flex-column justify-space-between pa-5">
                    <div class="d-flex justify-space-between">
                      <div
                        :class="
                          $vuetify.breakpoint.mobile
                            ? 'text-subtitle-1'
                            : 'text-h6'
                        "
                      >
                        Before Discount
                      </div>
                      <div class="text-decoration-line-through text-h6">
                        {{ currency }}

                        {{ amount | toMoney }}
                      </div>
                    </div>
                    <div class="mt-2">
                      <v-divider class="white"></v-divider>
                    </div>
                    <div class="d-flex justify-space-between mt-2">
                      <div
                        :class="
                          $vuetify.breakpoint.mobile
                            ? 'text-subtitle-1'
                            : 'text-h6'
                        "
                      >
                        After Discount
                      </div>
                      <div
                        :class="
                          $vuetify.breakpoint.mobile ? 'text-h6' : 'text-h4'
                        "
                      >
                        {{ currency }}
                        {{ discount.discountedAmount | toMoney }}
                      </div>
                    </div>
                  </div>
                  <div class="text-center">per month, billed quarterly</div>
                </div>
              </v-card>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="show_error" color="error">{{
      error_message
    }}</v-snackbar>
  </div>
</template>

<script>
import OTPComponent from "../agents/OTPComponent";
import PhoneNumberInput from "../agents/PhoneNumberInput";
import { setAccessToken } from "../../utils";

export default {
  name: "registerComponent",
  components: { PhoneNumberInput, OTPComponent },
  data() {
    return {
      error_message: "",
      show_error: false,
      otp_id: null,
      media: null,
      read_terms: true,
      checking: false,
      exist: false,
      loggingin: false,
      service_pop: false,
      retail_pop: false,
      success_msg: false,
      success_message: "",
      logo: [],
      imgsrc: "/img/logo_place.png",
      previewURL: "/img/logo_place.png",
      logo_file: {},
      business_name: "",
      business_name_rules: [(value) => !!value || "Business Name is Required."],
      invoice_id: "",
      invoiceID_rules: [(value) => !!value || "Invoice ID is required"],
      business_email: "",
      business_email_rules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],

      business_phone: "",
      business_phone_rules: [
        (value) => !!value || "Business Phone Number is Required.",
      ],
      business_bank_name: "",
      business_bank_rules: [
        (value) => !!value || "Business Bank Name is Required.",
      ],
      business_account_name: "",
      business_account_rules: [
        (value) => !!value || "Business Bank account name is Required.",
      ],
      business_account_number: "",

      business_account_number_rules: [
        (value) => !!value || "Business Bank Account Number is Required.",
      ],
      business_address: "",
      business_address_rules: [
        (value) => !!value || "Business Address is Required.",
      ],
      business_location: "",
      business_valid: false,
      account_infor: false,
      personal_infor: false,
      first_name: "",
      last_name: "",
      call_code: "",
      flag: "",
      signupForm: "",
      phone_number: "",
      new_password: "",
      confirm_password: "",
      new_email: "",
      progress: false,
      show_pass: false,
      show_pass1: false,
      show_pass2: false,
      remember_me: true,
      valid_form: false,
      login_error: false,
      subscription_package: "",
      error_msg: "",
      countries: null,
      softwarePlans: ["basic", "starter", "enterprise", "premium"],
      virtualPlans: ["venture", "pro", "plus"],
      amount: 0,
      currency: "",
      country: "",
      password: "",
      email: "",
      step: 1,
      is_service: false,
      is_retail_wholesale: true,
      preview: "",
      password_rules: [
        (value) => !!value || "Password is Required.",
        (value) =>
          value.length >= 8 || "Password must be at least 8 characters",
      ],
      first_name_rules: [(value) => !!value || "Name is Required."],
      country_rules: [(value) => !!value || "Country is Required."],
      phone_number_rules: [(value) => !!value || "Phone number is Required."],
      email_rules: [(value) => !!value || "E-mail is Required."],
      show: false,
      tab: null,
      user: {},
      platforms: [
        {
          value: "Telemarketing",
          text: "Salesperson from Built",
        },
        {
          value: "Facebook Post",
          text: "Facebook Post",
        },
        {
          value: "YouTube Advert",
          text: "YouTube Advert",
        },
        {
          value: "Blog Post",
          text: "Blog Post",
        },
        {
          value: "Google Search",
          text: "Google Search",
        },
        {
          value: "A Friend",
          text: "A Friend",
        },
        {
          value: "Co-worker",
          text: "Co-worker",
        },
        {
          value: "Twitter",
          text: "Twitter",
        },
        {
          value: "Linkedin",
          text: "Linkedin",
        },
        {
          value: "WhatsApp",
          text: "WhatsApp",
        },
        {
          value: "E-Mail",
          text: "E-Mail",
        },
        {
          value: "Radio",
          text: "Radio",
        },
        {
          value: "TV",
          text: "TV",
        },
        {
          value: "Newspaper",
          text: "Newspaper",
        },
        {
          value: "I cannot Remember",
          text: "I cannot Remember",
        },
        {
          value: "Others",
          text: "Others",
        },
      ],
      gender: { gender: "", value: "" },
      items: [
        { gender: "Male", value: "male" },
        { gender: "Female", value: "female" },
        { gender: "Prefer not to say", value: "other" },
      ],
    };
  },
  computed: {
    filteredMedia() {
      return this.$store.state.mediaLinks.filter((item) => {
        return item.status === "active";
      });
    },

    isValidPackage() {
      const validPackages = [...this.softwarePlans, "trial"];
      if (validPackages.includes(this.subscription_package)) {
        return true;
      }
      return false;
    },
    columnSize() {
      if (this.isValidPackage) {
        return "6";
      }
      return "12";
    },
    hasMediaToken() {
      return Boolean(localStorage.media_uuid);
    },
    discount() {
      if (this.softwarePlans.includes(this.subscription_package)) {
        const discountedAmount = (50 / 100) * this.amount;
        return { discountedAmount, percent: 50 };
      }
      //  else if (this.virtualPlans.includes(this.subscription_package)) {
      //   const discountedAmount = (70 / 100) * this.amount;
      //   return { discountedAmount, percent: 30 };
      // }
      return { discountedAmount: 0, percent: 0 };
    },
  },
  mounted() {
    this.getMedia();
    this.subscription_package = this.$route.query.package;
    this.amount = Number(this.$route.query.a);
    this.currency = this.$route.query.cu;
    localStorage.setItem("package", this.subscription_package);
    if (this.$store.state.user.is_loged_in) {
      this.$router.back();
    }
  },
  methods: {
    VerificationDone(otp) {
      if (otp.status) {
        this.otp_id = otp.id;
        this.sign_up();
      } else {
        this.error_message = otp.message;
        this.show_error = true;
        this.progress = false;
        return false;
      }
    },
    SendOTP() {
      if (!this.read_terms) {
        this.error_msg = "You have not agreed to our terms and conditions";
        this.login_error = true;
        this.progress = false;
        return false;
      }

      if (this.new_password !== this.confirm_password) {
        this.error_msg = "Passwords do not match!";
        this.login_error = true;
        this.progress = false;
        return false;
      }

      this.progress = true;
      axios
        .get("/api/auth/code/" + this.phone_number)
        .then((res) => {
          this.otp_id = res.data.id;
          this.step = 3;
          this.progress = false;
        })
        .catch((error) => {
          this.progress = false;
          // this.error_msg =
          //   error.response.status === 302
          //     ? error.response.data
          //     : "Something went wrong, could not process your signup";
          // this.login_error = true;
        });
    },

    getMedia() {
      axios.get("/api/media").then((res) => {
        this.$store.state.mediaLinks = res.data;

        if (this.hasMediaToken) {
          let media = this.$store.state.mediaLinks.find((link) => {
            return link.uuid === localStorage.media_uuid;
          });

          this.media = media.media_name;
        }
      });
    },
    check_exist() {
      if (this.new_email) {
        this.checking = true;

        axios.post("/api/checkemail", { email: this.new_email }).then((res) => {
          this.checking = false;
          this.exist = res.data.exist;
        });
      }
    },
    selectPass() {
      let pass = document.getElementById("filepass");
      pass.click();
    },

    passChange(e) {
      this.progress = true;
      this.logo = e.target.files[0];
      if (this.logo.size < 532598) {
        const fr = new FileReader();
        let logo_file = fr.readAsDataURL(this.logo);
        fr.onload = () => {
          this.imgsrc = fr.result;
          this.progress = false;
        };
      } else {
        this.error_msg = "Image size too big please select a smaller image";
        this.login_error = true;
      }
    },

    getAddressData(addressData, placeResultData, id) {
      if (addressData) {
        this.business_location =
          addressData.longitude + "," + addressData.latitude;
      }
    },

    goto_account() {
      if (this.$refs.person_form.validate()) {
        this.step = 2;
      }
    },

    sign_up(e) {
      if (this.$refs.account_form.validate()) {
        if (!this.read_terms) {
          this.error_msg = "You have not agreed to our terms and conditions";
          this.login_error = true;
          this.progress = false;
          return false;
        }

        if (this.new_password === this.confirm_password) {
          axios
            .post("/api/checkemail", { email: this.new_email })
            .then((res) => {
              this.checking = false;
              this.exist = res.data.exist;
              if (this.exist) {
                return false;
              }

              this.progress = true;
              console.log(this.gender);
              let signup_form = new FormData();
              signup_form.append("first_name", this.first_name);
              signup_form.append("last_name", this.last_name);
              signup_form.append("gender", this.gender);
              signup_form.append("phone_number", this.phone_number);
              signup_form.append("email", this.new_email);
              signup_form.append("media", this.media);
              signup_form.append("password", this.new_password);
              signup_form.append("ticket", this.otp_id);
              signup_form.append(
                "password_confirmation",
                this.confirm_password
              );

              axios
                .post("/api/auth/v2/signup", signup_form)
                .then((res) => {
                  if (res.status === 302) {
                    this.exist = true;
                    return false;
                  }

                  setAccessToken(res.data.access_token);
                  this.$router.push({ path: "/onboard" });
                  location.reload();
                })
                .catch((err) => {
                  this.progress = false;

                  // this.error_msg =
                  //   err.response.status === 302
                  //     ? err.response.data
                  //     : "Something went wrong, please try again";
                  // this.login_error = true;
                });
            });
        } else {
          this.login_error = true;
          this.error_msg =
            "Your passwords are not the same please check and try again";
        }
      }
    },
    handleGenderChange() {
      console.log(this.gender);
    },
  },
};
</script>

<style scoped></style>
