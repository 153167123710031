<template>
  <v-card>
    <v-card-text>
      <center>
        <v-icon
          color="blue darken-4"
          x-large
          class="ma-5"
          style="font-size: 5rem"
          >mdi-timer-refresh-outline</v-icon
        >
        <!--        <h2 class="font-weight-light">{{ $t("main.timeout_title") }}</h2>-->
        <h2 class="font-weight-light">
          Your session has expired due to inactivity.
        </h2>
      </center>

      <v-form ref="loginForm" @submit.prevent="login">
        <v-text-field
          v-if="user && user.email"
          outlined
          label="Password"
          autofocus
          v-model="password"
          type="password"
          :rules="$store.state.requiredRule"
          aria-autocomplete="none"
        ></v-text-field>

        <v-btn
          type="submit"
          :loading="progress"
          x-large
          dark
          color="blue darken-4"
          block
          depressed
          rounded
        >
          {{ $t("main.login") }}
          <v-icon>mdi-arrow-right</v-icon>
        </v-btn>
      </v-form>

      <v-btn class="mt-3" small text color="blue" to="/auth/login" block rounded
        >Cancel</v-btn
      >
    </v-card-text>
    <v-alert v-if="login_error" type="error" color="error lighten-5 red--text">
      <h4>{{ error_msg }}</h4>
    </v-alert>
  </v-card>
</template>

<script>
import { getAccessToken, setAccessToken } from "../../utils";

export default {
  name: "LiteLoginComponent",
  data() {
    return {
      password: "",
      login_error: false,
      error_msg: "",
      progress: false,
    };
  },
  methods: {
    login() {
      if (!this.user.email) {
        window.location = "/auth/login";
      } else {
        if (this.$refs.loginForm.validate()) {
          this.progress = true;

          let formdata = {
            email: this.user.email,
            password: this.password,
            remember_me: true,
          };

          axios
            .post("/api/auth/v2/login", formdata)
            .then((user) => {
              setAccessToken(user.data.access_token);
              axios.defaults.headers.common = {
                Authorization: `Bearer ${getAccessToken()}`,
              };

              localStorage.removeItem("WGPassport");

              if (user.data.twoFA) {
                window.location = "/auth/login";
              } else {
                if (!user.data.user.has_business) {
                  this.$router.push({ path: "/onboard" });
                  location.reload();
                } else {
                  this.$store.state.idle_dialog = false;
                  this.progress = false;
                }
              }
            })

            .catch((error) => {
              this.progress = false;

              // this.login_error = true;
              // if (error.response.status === 401) {
              //   this.error_msg =
              //     "Unauthorised: The credentials you provided could not be found in our records";
              // } else {
              //   this.error_msg =
              //     "Something went wrong, could not process login. please check your connections and try again ";
              // }
            });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.state.user.user_infor;
    },
  },
};
</script>

<style scoped></style>
